import { accountAtom } from 'atoms/account';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import * as accountService from '../services/account';
export default function useAccount() {
    const [account, setAccount] = useAtom(accountAtom);
    const navigate = useNavigate();

    async function login(username, password) {
        setAccount({ loading: true });
        try {
            const ac = await accountService.login(username, password);
            setAccount({ ...ac, loading: false });
            console.log(ac);
            navigate('/');
        } catch (error) {
            console.error(error);
            setAccount({ loading: false });
        }
    }
    useEffect(() => {
        if (!account || !account.accessToken) {
            navigate('/login');
        }
    }, []);
    return { account, login };
}

// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'execution',
    title: 'Execution',
    type: 'group',
    children: [
        {
            id: 'wormhole-execution',
            title: 'Wormhole',
            type: 'item',
            url: '/execution',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'cosmos-execution',
            title: 'Cosmos',
            type: 'item',
            url: '/execution',
            comingSoon: true,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'avalanche-execution',
            title: 'Avalanche',
            type: 'item',
            url: '/execution',
            comingSoon: true,
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'polkadot-execution',
            title: 'Polkadot',
            type: 'item',
            url: '/execution',
            comingSoon: true,
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
